import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../axiosConfig.ts';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';  // Adjusted import

interface AuthContextProps {
    token: string | null;
    login: (token: string) => void;
    logout: () => void;
}

interface JwtPayload {
    exp: number;
    // Add other fields if necessary
}

const AuthContext = createContext<AuthContextProps>({
    token: null,
    login: () => {},
    logout: () => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
    const navigate = useNavigate();

    const login = (newToken: string) => {
        setToken(newToken);
        localStorage.setItem('token', newToken);
    };

    const logout = () => {
        setToken(null);
        localStorage.removeItem('token');
        navigate('/login');
    };

    useEffect(() => {
        // Add a response interceptor
        const responseInterceptor = axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    // Token has expired or is invalid
                    logout();
                }
                return Promise.reject(error);
            }
        );

        // Proactively handle token expiry
        if (token) {
            let decoded: JwtPayload;
            try {
                decoded = jwt_decode(token);  // Accessing default export in namespace import
            } catch (error) {
                console.error('Failed to decode token:', error);
                logout();
                return;
            }
            const expiresAt = decoded.exp * 1000; // Convert to milliseconds
            const timeout = expiresAt - Date.now();

            if (timeout > 0) {
                const timer = setTimeout(() => {
                    logout();
                }, timeout);
                return () => {
                    clearTimeout(timer);
                    axios.interceptors.response.eject(responseInterceptor);
                };
            } else {
                // Token already expired
                logout();
            }
        }

        // Cleanup the interceptor on unmount
        return () => {
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, [token]); // Added `token` as a dependency

    return (
        <AuthContext.Provider value={{ token, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);