import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from '../axiosConfig.ts';
import { Logline } from '../types/Logline.ts';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FaEdit, FaTrash, FaFilePdf, FaPlus, FaSort, FaStar, FaMagic, FaFileAlt } from 'react-icons/fa';
import NavigationMenu from './NavigationMenu.tsx';
import HelpScreen from './HelpScreen.tsx';

const SavedLoglinesScreen: React.FC = () => {
    const [savedLoglines, setSavedLoglines] = useState<Logline[]>([]);
    const [sortOrder, setSortOrder] = useState<'chronological' | 'rating' | 'title'>('chronological');
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [isHelpOpen, setIsHelpOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        fetchSavedLoglines();
        adjustContainerHeight();
        window.addEventListener('resize', adjustContainerHeight);
        return () => window.removeEventListener('resize', adjustContainerHeight);
    }, []);

    const adjustContainerHeight = () => {
        if (containerRef.current) {
            const windowHeight = window.innerHeight;
            const containerTop = containerRef.current.getBoundingClientRect().top;
            const newHeight = windowHeight - containerTop - 20;
            containerRef.current.style.height = `${newHeight}px`;
        }
    };

    const fetchSavedLoglines = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/api/loglines/saved?sort=${sortOrder}`);
            setSavedLoglines(response.data);
        } catch (error) {
            console.error('Error fetching saved loglines:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchSavedLoglines();
    }, [sortOrder]); // Fetch loglines when sortOrder changes

    const handleDelete = async (id: string) => {
        try {
            await axios.delete(`/api/loglines/${id}`);
            fetchSavedLoglines();
        } catch (error) {
            console.error('Error deleting logline:', error);
        }
    };

    const handleRatingChange = async (id: string, newRating: number) => {
        try {
            await axios.put(`/api/loglines/${id}`, { rating: newRating });
            fetchSavedLoglines();
        } catch (error) {
            console.error('Error updating logline rating:', error);
        }
    };

    const handleEdit = (logline: Logline) => {
        navigate('/', { 
            state: { 
                editLogline: {
                    ...logline,
                    seed: logline.seed || '',
                }
            } 
        });
    };

    const handleExportPDF = () => {
        const doc = new jsPDF();
        doc.text('Saved Loglines', 14, 15);
        const tableColumn = ["Title", "Logline", "Rating"];
        const tableRows = savedLoglines.map(logline => [
            logline.title,
            logline.content,
            logline.rating.toString()
        ]);

        (doc as any).autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 20,
        });

        doc.save('saved_loglines.pdf');
    };

    const handleGenerateNew = () => {
        navigate('/', { state: { newGeneration: true } });
    };

    const sortedLoglines = useMemo(() => {
        return [...savedLoglines].sort((a, b) => {
            if (sortOrder === 'chronological') {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            } else if (sortOrder === 'rating') {
                return b.rating - a.rating;
            } else {
                return a.title.localeCompare(b.title);
            }
        });
    }, [savedLoglines, sortOrder]);

    const toggleHelp = () => setIsHelpOpen(!isHelpOpen);

    const handleExportSinglePDF = (logline: Logline) => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Logline Details', 14, 15);

        (doc as any).autoTable({
            startY: 25,
            head: [['Title', 'Content', 'Rating']],
            body: [
                [
                    logline.title,
                    logline.content,
                    '★'.repeat(logline.rating) + '☆'.repeat(5 - logline.rating)
                ]
            ],
            styles: { fontSize: 12, cellPadding: 5 },
            columnStyles: {
                0: { cellWidth: 50 },
                1: { cellWidth: 'auto' },
                2: { cellWidth: 30, halign: 'center' }
            },
            headStyles: { fillColor: [200, 200, 200], textColor: [0, 0, 0], fontStyle: 'bold' }
        });

        doc.save(`${logline.title.replace(/\s+/g, '_')}_logline.pdf`);
    };

    return (
        <div className="min-h-screen bg-white text-black p-4 pb-20">
            <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md space-y-6 p-6">
                <h1 className="text-2xl font-serif font-bold text-center text-black">Saved Loglines</h1>
                
                {isLoading ? (
                    <div className="text-center">Loading...</div>
                ) : sortedLoglines.length === 0 ? (
                    <p className="text-center">No saved loglines found.</p>
                ) : (
                    <>
                        <div className="flex justify-between items-center">
                            <div className="flex items-center">
                                <FaSort className="mr-2 text-black" />
                                <select
                                    value={sortOrder}
                                    onChange={(e) => setSortOrder(e.target.value as 'chronological' | 'rating' | 'title')}
                                    className="p-2 border rounded bg-white text-black text-sm border-gray-300"
                                >
                                    <option value="chronological">Date (Newest First)</option>
                                    <option value="rating">Rating (Highest First)</option>
                                    <option value="title">Title (A-Z)</option>
                                </select>
                            </div>
                            <button
                                onClick={handleExportPDF}
                                className="flex flex-col items-center p-2 bg-black text-white rounded-full hover:bg-gray-800"
                            >
                                <FaFilePdf size={20} />
                                <span className="text-xs mt-1">Export All</span>
                            </button>
                        </div>

                        <div ref={containerRef} className="mt-4 space-y-4 overflow-y-auto">
                            {savedLoglines.map((logline) => (
                                <div key={logline._id} className="border p-4 rounded shadow bg-white">
                                    <h2 className="text-lg font-serif font-bold text-black">{logline.title}</h2>
                                    <p className="mt-2 text-black text-sm">{logline.content}</p>
                                    <div className="mt-4 flex justify-between items-center">
                                        <div className="flex">
                                            {[1, 2, 3, 4, 5].map((star) => (
                                                <FaStar
                                                    key={star}
                                                    className={`cursor-pointer ${star <= logline.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                                                    size={18}
                                                    onClick={() => handleRatingChange(logline._id, star)}
                                                />
                                            ))}
                                        </div>
                                        <div className="flex space-x-4">
                                            <button
                                                onClick={() => handleEdit(logline)}
                                                className="flex flex-col items-center text-black hover:text-gray-700"
                                            >
                                                <FaEdit size={18} />
                                                <span className="text-xs mt-1">Edit</span>
                                            </button>
                                            <button
                                                onClick={() => handleDelete(logline._id)}
                                                className="flex flex-col items-center text-black hover:text-gray-700"
                                            >
                                                <FaTrash size={18} />
                                                <span className="text-xs mt-1">Delete</span>
                                            </button>
                                            <button
                                                onClick={() => handleExportSinglePDF(logline)}
                                                className="flex flex-col items-center text-black hover:text-gray-700"
                                            >
                                                <FaFilePdf size={18} />
                                                <span className="text-xs mt-1">Export</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            <NavigationMenu onHelpClick={toggleHelp} />
            <HelpScreen isOpen={isHelpOpen} onClose={() => setIsHelpOpen(false)} />
        </div>
    );
};

export default SavedLoglinesScreen;