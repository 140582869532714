import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaMagic, FaArchive, FaFileAlt, FaSignOutAlt, FaQuestionCircle, FaBars } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext.tsx';
import { motion, AnimatePresence } from 'framer-motion';

interface NavigationMenuProps {
    onHelpClick: () => void;
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({ onHelpClick }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { logout } = useAuth();
    const [isOpen, setIsOpen] = useState(false);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const handleNavigation = (path: string) => {
        setIsOpen(false);
        if (location.pathname !== path) {
            navigate(path);
        }
    };

    const menuItems = [
        { icon: FaMagic, label: 'Logliner', action: () => handleNavigation('/') },
        { icon: FaArchive, label: 'Loglines', action: () => handleNavigation('/saved-loglines') },
        { icon: FaFileAlt, label: 'One-Pagers', action: () => handleNavigation('/saved-one-pagers') },
        { icon: FaQuestionCircle, label: 'Help', action: () => { setIsOpen(false); onHelpClick(); } },
        { icon: FaSignOutAlt, label: 'Logout', action: handleLogout },
    ];

    return (
        <div className="fixed top-4 right-4 z-50">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="bg-white text-black p-3 rounded-full shadow-lg border border-gray-300"
            >
                <FaBars size={24} />
            </button>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        className="absolute top-16 right-0 bg-white rounded-lg shadow-xl p-4 border border-gray-300"
                    >
                        {menuItems.map((item, index) => (
                            <button
                                key={index}
                                onClick={item.action}
                                className="flex flex-col items-center w-full text-center p-2 hover:bg-gray-100 rounded"
                            >
                                <item.icon size={20} className="text-black mb-1" />
                                <span className="text-xs">{item.label}</span>
                            </button>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default NavigationMenu;