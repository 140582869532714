import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'; // Remove Router import
import LoglineGenerator from './components/LoglineGenerator.tsx';
import OnePagerGenerator from './components/OnePagerGenerator.tsx';
import SavedLoglinesScreen from './components/SavedLoglinesScreen.tsx';
import SavedOnePagersScreen from './components/SavedOnePagersScreen.tsx';
import Login from './components/Login.tsx';
import Register from './components/Register.tsx';
import HelpScreen from './components/HelpScreen.tsx';
import { useAuth } from './contexts/AuthContext.tsx';

const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const { token } = useAuth();
    return token ? children : <Navigate to="/login" />;
};

function App() {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
                path="/"
                element={
                    <PrivateRoute>
                        <LoglineGenerator />
                    </PrivateRoute>
                }
            />
            <Route
                path="/one-pager"
                element={
                    <PrivateRoute>
                        <OnePagerGenerator />
                    </PrivateRoute>
                }
            />
            <Route
                path="/saved-loglines"
                element={
                    <PrivateRoute>
                        <SavedLoglinesScreen />
                    </PrivateRoute>
                }
            />
            <Route
                path="/saved-one-pagers"
                element={
                    <PrivateRoute>
                        <SavedOnePagersScreen />
                    </PrivateRoute>
                }
            />
            <Route
                path="/help"
                element={
                    <PrivateRoute>
                        <HelpScreen />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
}

export default App;