import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../axiosConfig.ts';
import { calculateFontSize } from '../utils/fontSizeAdjuster.ts';
import { FaSpinner, FaMagic, FaRedo, FaSave, FaFileAlt, FaUndo, FaStar } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import NavigationMenu from './NavigationMenu.tsx';
import HelpScreen from './HelpScreen.tsx';
import { useAuth } from '../contexts/AuthContext.tsx';
import Notification from './Notification.tsx';

const LoglineGenerator: React.FC = () => {
    const [seed, setSeed] = useState<string>(() => localStorage.getItem('currentSeed') || '');
    const [logline, setLogline] = useState<string>(() => localStorage.getItem('currentLogline') || '');
    const [title, setTitle] = useState<string>(() => localStorage.getItem('currentTitle') || '');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [isGenerated, setIsGenerated] = useState<boolean>(() => Boolean(localStorage.getItem('isGenerated')));
    const [stage, setStage] = useState<'seed' | 'logline'>(() => localStorage.getItem('currentStage') as 'seed' | 'logline' || 'seed');
    const [comments, setComments] = useState<string>(() => localStorage.getItem('currentComments') || '');
    const location = useLocation();
    const [titleFontSize, setTitleFontSize] = useState<number>(18);
    const titleInputRef = useRef<HTMLInputElement>(null);
    const loglineTextareaRef = useRef<HTMLTextAreaElement>(null);
    const [showRatingModal, setShowRatingModal] = useState<boolean>(false);
    const [rating, setRating] = useState<number>(0);
    const [isHelpOpen, setIsHelpOpen] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [isNavigating, setIsNavigating] = useState(false);

    useEffect(() => {
        const editLogline = location.state?.editLogline;
        const isNewGeneration = location.state?.NewGeneration;

        if (editLogline) {
            setSeed(editLogline.seed || '');
            setLogline(editLogline.content);
            setTitle(editLogline.title);
            setStage('logline');
            setIsGenerated(true);
            navigate('/', { replace: true, state: {} });
        } else if (isNewGeneration) {
            handleResetSeed();
            navigate('/', { replace: true, state: {} });
        }
    }, [location.state, navigate]);

    useEffect(() => {
        localStorage.setItem('currentSeed', seed);
        localStorage.setItem('currentLogline', logline);
        localStorage.setItem('currentTitle', title);
        localStorage.setItem('isGenerated', isGenerated.toString());
        localStorage.setItem('currentStage', stage);
        localStorage.setItem('currentComments', comments);
    }, [seed, logline, title, isGenerated, stage, comments]);

    useEffect(() => {
        const adjustFontSize = () => {
            if (titleInputRef.current) {
                const maxWidth = titleInputRef.current.offsetWidth - 16;
                const newFontSize = calculateFontSize(title, maxWidth, 18);
                setTitleFontSize(newFontSize);
            }
        };

        adjustFontSize();
        window.addEventListener('resize', adjustFontSize);

        return () => window.removeEventListener('resize', adjustFontSize);
    }, [title]);

    useEffect(() => {
        const adjustTextareaHeight = () => {
            if (loglineTextareaRef.current) {
                loglineTextareaRef.current.style.height = 'auto';
                loglineTextareaRef.current.style.height = `${loglineTextareaRef.current.scrollHeight}px`;
            }
        };

        adjustTextareaHeight();
    }, [logline]);

    const handleGenerate = async (isChanging: boolean = false, isRefining: boolean = false) => {
        setIsLoading(true);
        setError('');
        try {
            const payload = {
                seed,
                logline: stage === 'logline' ? logline : '',
                isChanging,
                isRefining,
                comments: comments || ''
            };
            const endpoint = '/api/loglines/generate';
            const response = await axios.post(endpoint, payload);
            if (response.data && response.data.logline && response.data.title) {
                setLogline(response.data.logline);
                setTitle(response.data.title);
                setStage('logline');
                setIsGenerated(true);
                setComments('');
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            console.error('Error generating logline:', error);
            if (error.response && error.response.data && error.response.data.details) {
                setError(`Failed to generate logline: ${error.response.data.details}`);
            } else {
                setError('Failed to generate logline. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleSave = async () => {
        setShowRatingModal(true);
    };

    const handleRatingSubmit = async () => {
        setIsLoading(true);
        setError('');
        try {
            const response = await axios.post('/api/loglines/save', { title, logline: logline, rating, seed });
            setShowNotification(true);
            setShowRatingModal(false);
            setRating(0);
        } catch (error) {
            console.error('Error saving logline:', error);
            setError(error instanceof Error ? error.message : 'Failed to save logline. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleResetSeed = useCallback(() => {
        setSeed('');
        setLogline('');
        setTitle('');
        setIsGenerated(false);
        setStage('seed');
        setComments('');
        localStorage.removeItem('currentSeed');
        localStorage.removeItem('currentLogline');
        localStorage.removeItem('currentTitle');
        localStorage.removeItem('isGenerated');
        localStorage.removeItem('currentStage');
        localStorage.removeItem('currentComments');
    }, []);

    const handleGenerateOnePager = useCallback(() => {
        if (title && logline && !isNavigating) {
            setIsNavigating(true);
            // Clear the localStorage items related to OnePagerGenerator
            localStorage.removeItem('currentOnePager');
            
            navigate('/one-pager', { 
                state: { 
                    title, 
                    logline,
                    newGeneration: true // Flag to indicate a new generation is needed
                } 
            });
        } else {
            setError('Title and logline are required to generate a one-pager.');
        }
    }, [title, logline, isNavigating, navigate]);

    useEffect(() => {
        return () => {
            setIsNavigating(false);
        };
    }, []);

    const toggleHelp = () => setIsHelpOpen(!isHelpOpen);

    return (
        <div className="container mx-auto px-4 py-8 relative min-h-screen bg-white text-black">
            <NavigationMenu onHelpClick={toggleHelp} />
            <div className="w-full max-w-md mx-auto space-y-6 p-6 relative">
                <h1 className="text-3xl font-serif font-bold text-center text-black">Logline AI</h1>
                {stage === 'seed' ? (
                    <div className="space-y-4">
                        <textarea
                            id="seed-idea"
                            value={seed}
                            onChange={(e) => setSeed(e.target.value)}
                            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 bg-white text-black"
                            placeholder="enter core concept(s)"
                            rows={3}
                        />
                        <div className="flex justify-center mt-4">
                            <button
                                onClick={() => handleGenerate(false, false)}
                                className="w-16 h-16 rounded-full bg-white text-black shadow-lg hover:bg-gray-100 transition-colors duration-200 relative border border-gray-300"
                            >
                                <div className="absolute inset-0 flex items-center justify-center">
                                    {isLoading ? <FaSpinner className="animate-spin" size={24} /> : <FaMagic size={24} />}
                                </div>
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="space-y-4">
                        <input
                            id="movie-title"
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            ref={titleInputRef}
                            style={{ fontSize: `${titleFontSize}px` }}
                            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 font-bold"
                            placeholder="Movie Title"
                        />
                        <textarea
                            id="movie-concept"
                            ref={loglineTextareaRef}
                            value={logline}
                            onChange={(e) => setLogline(e.target.value)}
                            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-all duration-300"
                            placeholder="Your generated movie concept will appear here..."
                            style={{ minHeight: '100px', resize: 'none', overflow: 'hidden' }}
                        />
                        <div className="flex justify-between items-center">
                            <button 
                                onClick={() => handleGenerate(true, false)}
                                className="flex flex-col items-center text-black"
                            >
                                <FaRedo size={24} />
                                <span className="text-xs mt-1">Change</span>
                            </button>
                            <button 
                                onClick={() => handleGenerate(false, true)}
                                className="flex flex-col items-center text-black"
                            >
                                <FaMagic size={24} />
                                <span className="text-xs mt-1">Refine</span>
                            </button>
                        </div>
                        <textarea
                            id="feedback"
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            placeholder="Enter any feedback or comments for refinement..."
                            rows={3}
                        />
                        {isGenerated && (
                            <div className="flex justify-between items-center">
                                <button
                                    onClick={handleSave}
                                    className="flex flex-col items-center text-black hover:text-gray-700"
                                >
                                    <FaSave size={24} />
                                    <span className="text-xs mt-1">Save</span>
                                </button>
                                <button
                                    onClick={handleGenerateOnePager}
                                    disabled={isNavigating}
                                    className="flex flex-col items-center text-black hover:text-gray-700"
                                >
                                    <FaFileAlt size={24} />
                                    <span className="text-xs mt-1">Generate</span>
                                    <span className="text-xs">One-Pager</span>
                                </button>
                                <button
                                    onClick={handleResetSeed}
                                    className="flex flex-col items-center text-black hover:text-gray-700"
                                >
                                    <FaUndo size={24} />
                                    <span className="text-xs mt-1">Clear</span>
                                </button>
                            </div>
                        )}
                    </div>
                )}

                <AnimatePresence>
                    {isLoading && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="absolute inset-0 bg-white bg-opacity-80 flex items-center justify-center z-50"
                        >
                            <FaSpinner className="text-black text-4xl animate-spin" />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <HelpScreen isOpen={isHelpOpen} onClose={() => setIsHelpOpen(false)} />
            {showRatingModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg">
                        <h2 className="text-xl font-bold mb-4">Rate this logline</h2>
                        <div className="flex mb-4">
                            {[1, 2, 3, 4, 5].map((star) => (
                                <FaStar
                                    key={star}
                                    className={`cursor-pointer ${star <= rating ? 'text-yellow-400' : 'text-gray-300'}`}
                                    size={32}
                                    onClick={() => setRating(star)}
                                />
                            ))}
                        </div>
                        <div className="flex justify-end">
                            <button
                                onClick={() => setShowRatingModal(false)}
                                className="mr-2 px-4 py-2 bg-gray-200 rounded"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleRatingSubmit}
                                className="px-4 py-2 bg-black text-white rounded"
                                disabled={rating === 0}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Notification
                message="Logline saved successfully!"
                isVisible={showNotification}
                onClose={() => setShowNotification(false)}
            />
        </div>
    );
};

export default LoglineGenerator;