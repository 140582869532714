export function calculateFontSize(text: string, maxWidth: number, maxFontSize: number): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) return maxFontSize;

    let fontSize = maxFontSize;
    context.font = `bold ${fontSize}px sans-serif`;
    let width = context.measureText(text).width;

    while (width > maxWidth && fontSize > 10) {
        fontSize--;
        context.font = `bold ${fontSize}px sans-serif`;
        width = context.measureText(text).width;
    }

    return fontSize;
}