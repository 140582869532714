import React from 'react';
import { FaTimes } from 'react-icons/fa';

interface HelpScreenProps {
    isOpen: boolean;
    onClose: () => void;
}

const HelpScreen: React.FC<HelpScreenProps> = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-xl max-w-lg w-full max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center p-4 border-b border-gray-200">
                    <h1 className="text-2xl font-serif font-bold text-black">Help</h1>
                    <button 
                        onClick={onClose}
                        className="text-black hover:text-gray-700 transition-colors duration-200"
                    >
                        <FaTimes size={24} />
                    </button>
                </div>
                <div className="p-6 space-y-4 text-black">
                    <h2 className="text-xl font-serif font-semibold">Welcome to the Movie Brainstormer!</h2>
                    <p>Here's a quick guide to using our app:</p>
                    <ol className="list-decimal list-inside space-y-2">
                        <li><strong>Generate Ideas:</strong> Start on the Logliner screen and enter a seed idea. Click the magic wand to generate your movie concept!</li>
                        <li><strong>Save Ideas:</strong> Like what you see? Save your favorite concepts for later.</li>
                        <li><strong>Create One-Pagers:</strong> Turn your best ideas into detailed one-pagers.</li>
                        <li><strong>View Saved Work:</strong> Access your saved loglines and one-pagers anytime.</li>
                        <li><strong>Edit and Refine:</strong> Don't be afraid to tweak and improve your work!</li>
                        <li><strong>Change Concept:</strong> Use the "Change" button to generate a completely new concept based on your original seed idea.</li>
                        <li><strong>Refine Concept:</strong> Use the "Refine" button to improve and iterate on your current concept.</li>
                    </ol>
                    <p>Remember, the best movie ideas often come from unexpected places. Happy brainstorming!</p>
                </div>
            </div>
        </div>
    );
};

export default HelpScreen;