import React, { useState, useEffect, useRef } from 'react';
import axios from '../axiosConfig.ts';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaFilePdf, FaEye, FaSort, FaTimes, FaPlus, FaStar } from 'react-icons/fa';
import { OnePager } from '../types/OnePager.ts';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import NavigationMenu from './NavigationMenu.tsx';
import HelpScreen from './HelpScreen.tsx';

const SavedOnePagersScreen: React.FC = () => {
    const [savedOnePagers, setSavedOnePagers] = useState<OnePager[]>([]);
    const [sortOrder, setSortOrder] = useState<'chronological' | 'rating' | 'title'>('chronological');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedOnePager, setSelectedOnePager] = useState<OnePager | null>(null);
    const [isHelpOpen, setIsHelpOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        fetchSavedOnePagers();
        adjustContainerHeight();
        window.addEventListener('resize', adjustContainerHeight);
        return () => window.removeEventListener('resize', adjustContainerHeight);
    }, []);

    useEffect(() => {
        fetchSavedOnePagers();
    }, [sortOrder]);

    useEffect(() => {
        console.log('savedOnePagers state updated:', savedOnePagers);
    }, [savedOnePagers]);

    const adjustContainerHeight = () => {
        if (containerRef.current) {
            const windowHeight = window.innerHeight;
            const containerTop = containerRef.current.getBoundingClientRect().top;
            const newHeight = windowHeight - containerTop - 20;
            containerRef.current.style.height = `${newHeight}px`;
        }
    };

    const fetchSavedOnePagers = async () => {
        setIsLoading(true);
        try {
            console.log('Fetching saved one-pagers...');
            const response = await axios.get(`/api/one-pagers/saved?sort=${sortOrder}`);
            console.log('Fetched one-pagers response:', response);
            if (Array.isArray(response.data.onePagers)) {
                console.log('Fetched one-pagers:', response.data.onePagers);
                setSavedOnePagers(response.data.onePagers);
            } else {
                console.error('Unexpected response format:', response.data);
                setError('Unexpected response format from server');
            }
        } catch (error) {
            console.error('Error fetching saved one-pagers:', error);
            setError('Failed to fetch one-pagers. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            await axios.delete(`/api/one-pagers/${id}`);
            fetchSavedOnePagers();
        } catch (error) {
            console.error('Error deleting one-pager:', error);
        }
    };

    const handleEdit = (onePager: OnePager) => {
        navigate('/one-pager', { 
            state: { 
                editOnePager: onePager,
                title: onePager.title,
                logline: onePager.logline
            } 
        });
    };

    const handleViewFullContent = (onePager: OnePager) => {
        setSelectedOnePager(onePager);
    };

    const closeModal = () => {
        setSelectedOnePager(null);
    };

    const exportToPDF = (onePager: OnePager) => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        const margin = 20;

        // Title
        doc.setFontSize(24);
        doc.setFont("helvetica", "bold");
        doc.setTextColor(44, 62, 80);
        doc.text(onePager.title, margin, margin + 10);

        // Logline
        doc.autoTable({
            startY: margin + 20,
            head: [['Logline']],
            body: [[onePager.logline]],
            styles: {
                fontSize: 12,
                cellPadding: 5,
                fontStyle: 'italic',
                textColor: [52, 73, 94],
                halign: 'left'
            },
            headStyles: {
                fillColor: [240, 240, 240],
                textColor: [44, 62, 80],
                fontStyle: 'bold'
            }
        });

        // One-Pager Content
        doc.autoTable({
            startY: doc.lastAutoTable.finalY + 10,
            head: [['Synopsis']],
            body: [[onePager.content]],
            styles: {
                fontSize: 12,
                cellPadding: 5,
                textColor: [0, 0, 0],
                halign: 'left'
            },
            headStyles: {
                fillColor: [240, 240, 240],
                textColor: [44, 62, 80],
                fontStyle: 'bold'
            }
        });

        // Footer
        doc.setFontSize(10);
        doc.setTextColor(149, 165, 166);
        doc.text(`Generated on ${new Date().toLocaleDateString()}`, margin, pageHeight - 10);

        doc.save(`${onePager.title.replace(/\s+/g, '_')}_one_pager.pdf`);
    };

    const exportAllToPDF = () => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        const margin = 20;

        savedOnePagers.forEach((onePager, index) => {
            if (index > 0) {
                doc.addPage();
            }

            // Title
            doc.setFontSize(24);
            doc.setFont("helvetica", "bold");
            doc.setTextColor(44, 62, 80);
            doc.text(onePager.title, margin, margin + 10);

            // Logline
            doc.autoTable({
                startY: margin + 20,
                head: [['Logline']],
                body: [[onePager.logline]],
                styles: {
                    fontSize: 12,
                    cellPadding: 5,
                    fontStyle: 'italic',
                    textColor: [52, 73, 94],
                    halign: 'left'
                },
                headStyles: {
                    fillColor: [240, 240, 240],
                    textColor: [44, 62, 80],
                    fontStyle: 'bold'
                }
            });

            // One-Pager Content
            doc.autoTable({
                startY: doc.lastAutoTable.finalY + 10,
                head: [['Synopsis']],
                body: [[onePager.content]],
                styles: {
                    fontSize: 12,
                    cellPadding: 5,
                    textColor: [0, 0, 0],
                    halign: 'left'
                },
                headStyles: {
                    fillColor: [240, 240, 240],
                    textColor: [44, 62, 80],
                    fontStyle: 'bold'
                }
            });

            // Footer with page number
            const pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
            doc.setFontSize(10);
            doc.setTextColor(149, 165, 166);
            doc.text(`Generated on ${new Date().toLocaleDateString()} | Page ${pageNumber}`, margin, pageHeight - 10);
        });

        doc.save('all_one_pagers.pdf');
    };

    const handleRatingChange = async (id: string, newRating: number) => {
        try {
            const response = await axios.put(`/api/one-pagers/${id}`, { rating: newRating });
            console.log('Server response:', response.data);
            await fetchSavedOnePagers(); // Refetch to get the updated list
            console.log('Fetched one-pagers after update:', savedOnePagers);
        } catch (error) {
            console.error('Error updating one-pager rating:', error);
            setError('Failed to update rating. Please try again.');
        }
    };

    const toggleHelp = () => setIsHelpOpen(!isHelpOpen);

    return (
        <div className="min-h-screen bg-white text-black p-4 pb-20">
            <div className="max-w-4xl mx-auto space-y-6 p-6">
                <h1 className="text-3xl font-serif font-bold text-center">Saved One-Pagers</h1>
                
                {isLoading ? (
                    <div className="text-center">Loading...</div>
                ) : savedOnePagers.length === 0 ? (
                    <p className="text-center">No saved one-pagers found.</p>
                ) : (
                    <>
                        <div className="flex justify-between items-center">
                            <div className="flex items-center">
                                <FaSort className="mr-2" />
                                <select
                                    value={sortOrder}
                                    onChange={(e) => setSortOrder(e.target.value as 'chronological' | 'rating' | 'title')}
                                    className="p-2 border rounded bg-white text-black text-sm border-gray-300"
                                >
                                    <option value="chronological">Date (Newest First)</option>
                                    <option value="rating">Rating (Highest First)</option>
                                    <option value="title">Title (A-Z)</option>
                                </select>
                            </div>
                            <button
                                onClick={exportAllToPDF}
                                className="flex flex-col items-center p-2 bg-white text-black rounded-full hover:bg-gray-100 border border-gray-300"
                            >
                                <FaFilePdf size={20} />
                                <span className="text-xs mt-1">Export All</span>
                            </button>
                        </div>

                        <div ref={containerRef} className="mt-4 space-y-4 overflow-y-auto">
                            {savedOnePagers.map((onePager) => (
                                <div key={onePager._id} className="border p-4 rounded shadow bg-white">
                                    <h2 className="text-lg font-serif font-bold">{onePager.title}</h2>
                                    <p className="mt-2 text-sm">{onePager.logline}</p>
                                    <div className="mt-4 flex justify-between items-center">
                                        <div className="flex">
                                            {[1, 2, 3, 4, 5].map((star) => (
                                                <FaStar
                                                    key={star}
                                                    className={`cursor-pointer ${star <= onePager.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                                                    onClick={() => handleRatingChange(onePager._id, star)}
                                                />
                                            ))}
                                        </div>
                                        <div className="flex space-x-2">
                                            <button
                                                onClick={() => handleViewFullContent(onePager)}
                                                className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                            >
                                                <FaEye />
                                            </button>
                                            <button
                                                onClick={() => handleEdit(onePager)}
                                                className="p-2 bg-green-500 text-white rounded hover:bg-green-600"
                                            >
                                                <FaEdit />
                                            </button>
                                            <button
                                                onClick={() => handleDelete(onePager._id)}
                                                className="p-2 bg-red-500 text-white rounded hover:bg-red-600"
                                            >
                                                <FaTrash />
                                            </button>
                                            <button
                                                onClick={() => exportToPDF(onePager)}
                                                className="p-2 bg-purple-500 text-white rounded hover:bg-purple-600"
                                            >
                                                <FaFilePdf />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>

            {/* Modal for full content view */}
            {selectedOnePager && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
                    <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-serif font-bold">{selectedOnePager.title}</h2>
                            <button onClick={closeModal} className="text-black hover:text-gray-700">
                                <FaTimes size={24} />
                            </button>
                        </div>
                        <div className="prose max-w-none">
                            {selectedOnePager.content.split('\n').map((paragraph, index) => (
                                <p key={index} className="mb-4">{paragraph}</p>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            <NavigationMenu onHelpClick={toggleHelp} />
            <HelpScreen isOpen={isHelpOpen} onClose={() => setIsHelpOpen(false)} />
            {error && <p className="text-red-500 text-center">{error}</p>}
        </div>
    );
};

export default SavedOnePagersScreen;