import React, { useState } from 'react';

interface FeedbackDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (feedback: string) => void;
  actionType: 'change' | 'refine';
}

const FeedbackDialog: React.FC<FeedbackDialogProps> = ({ isOpen, onClose, onSubmit, actionType }) => {
  const [feedback, setFeedback] = useState('');

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">
          {actionType === 'change' ? 'Change One-Pager' : 'Refine One-Pager'}
        </h2>
        <p className="mb-4">
          Would you like to provide any feedback or guidance for this {actionType}?
        </p>
        <textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500"
          placeholder="Enter your feedback here (optional)"
          rows={3}
        />
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={() => onSubmit(feedback)}
            className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800"
          >
            {actionType === 'change' ? 'Change' : 'Refine'}
          </button>
          <button
            onClick={() => onSubmit('')}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            {actionType === 'change' ? 'Change' : 'Refine'} without feedback
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackDialog;