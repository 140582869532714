import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from '../axiosConfig.ts';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaSpinner, FaMagic, FaSave, FaUndo, FaRedo, FaStar } from 'react-icons/fa';
import NavigationMenu from './NavigationMenu.tsx';
import HelpScreen from './HelpScreen.tsx';
import Notification from './Notification.tsx';
import { calculateFontSize } from '../utils/fontSizeAdjuster.ts';
import FeedbackDialog from './FeedbackDialogue.tsx';
import { motion, AnimatePresence } from 'framer-motion';

interface OnePagerGeneratorProps {
    title?: string;
    logline?: string;
    onClose?: () => void;
}

interface GeneratedOnePager {
    _id?: string;
    title: string;
    logline: string;
    content: string;
    rating?: number;
}

const OnePagerGenerator: React.FC<OnePagerGeneratorProps> = ({ onClose }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { 
        title: initialTitle, 
        logline: initialLogline, 
        editOnePager,
        newGeneration
    } = location.state || {};

    const [title, setTitle] = useState(initialTitle || editOnePager?.title || '');
    const [logline, setLogline] = useState(initialLogline || editOnePager?.logline || '');
    const [onePager, setOnePager] = useState<GeneratedOnePager | null>(editOnePager || null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [showNotification, setShowNotification] = useState(false);

    const contentRef = useRef<HTMLTextAreaElement>(null);

    const [isHelpOpen, setIsHelpOpen] = useState(false);
    const [isLoglineExpanded, setIsLoglineExpanded] = useState(false);
    const [showRatingModal, setShowRatingModal] = useState(false);
    const [rating, setRating] = useState(0);

    const titleInputRef = useRef<HTMLTextAreaElement>(null);
    const [titleFontSize, setTitleFontSize] = useState(24);
    const [titleLines, setTitleLines] = useState(1);

    const [isFullscreenContent, setIsFullscreenContent] = useState(false);

    const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
    const [feedbackActionType, setFeedbackActionType] = useState<'change' | 'refine'>('change');

    const loglineTextareaRef = useRef<HTMLTextAreaElement>(null);

    const adjustTextareaHeight = useCallback(() => {
        if (loglineTextareaRef.current) {
            loglineTextareaRef.current.style.height = 'auto';
            loglineTextareaRef.current.style.height = `${loglineTextareaRef.current.scrollHeight}px`;
        }
    }, []);

    useEffect(() => {
        adjustTextareaHeight();
    }, [logline, adjustTextareaHeight]);

    useEffect(() => {
        if (editOnePager) {
            setTitle(editOnePager.title);
            setLogline(editOnePager.logline);
            setOnePager(editOnePager);
        } else if (newGeneration) {
            generateOnePager();
        }
    }, [editOnePager, newGeneration]);

    const generateOnePager = useCallback(async (isChanging: boolean = false, isRefining: boolean = false, feedback: string = '') => {
        if (isLoading) return;
        
        setIsLoading(true);
        setError('');

        try {
            const payload = { 
                title, 
                logline, 
                isChanging, 
                isRefining, 
                comments: feedback,
                onePager: onePager ? {
                    _id: onePager._id,
                    content: onePager.content,
                    fullStory: onePager.fullStory,
                    rating: onePager.rating
                } : null
            };
            const response = await axios.post<{ onePager: GeneratedOnePager }>(
                '/api/one-pagers/generate', 
                payload
            );
            
            if (response.data && response.data.onePager) {
                setOnePager(response.data.onePager);
                setTitle(response.data.onePager.title);
                setLogline(response.data.onePager.logline);
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            console.error('Error generating one-pager:', error);
            setError('Failed to generate one-pager. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, [title, logline, onePager, isLoading]);

    useEffect(() => {
        adjustTitleSize();
    }, [title]);

    useEffect(() => {
        if (onePager) {
            localStorage.setItem('currentOnePager', JSON.stringify(onePager));
        }
    }, [onePager]);

    const adjustTitleSize = () => {
        if (titleInputRef.current) {
            const maxWidth = titleInputRef.current.offsetWidth - 40;
            let fontSize = calculateFontSize(title, maxWidth, 24);
            let lines = 1;

            if (fontSize < 12) {
                fontSize = calculateFontSize(title.split(' ').slice(0, Math.ceil(title.split(' ').length / 2)).join(' '), maxWidth, 24);
                lines = 2;
            }

            if (fontSize < 12 && lines === 2) {
                fontSize = calculateFontSize(title.split(' ').slice(0, Math.ceil(title.split(' ').length / 3)).join(' '), maxWidth, 24);
                lines = 3;
            }

            setTitleFontSize(fontSize);
            setTitleLines(lines);

            titleInputRef.current.style.height = 'auto';
            titleInputRef.current.style.height = `${titleInputRef.current.scrollHeight}px`;
        }
    };

    const handleFeedbackSubmit = (feedback: string) => {
        setIsFeedbackDialogOpen(false);
        if (feedbackActionType === 'change') {
            generateOnePager(true, false, feedback);
        } else {
            generateOnePager(false, true, feedback);
        }
    };

    const handleSave = () => {
        if (!onePager) {
            setError('Cannot save: No one-pager has been generated yet.');
            return;
        }
        setShowRatingModal(true);
    };

    const handleRatingSubmit = async () => {
        if (!title || !logline || !onePager) {
            setError('Cannot save: Missing title, logline, or one-pager content.');
            return;
        }
        setIsSaving(true);
        try {
            // Determine the endpoint based on the presence of _id
            const endpoint = onePager._id ? `/api/one-pagers/${onePager._id}` : '/api/one-pagers';
            const method = onePager._id ? 'put' : 'post';
            const payload = {
                title,
                logline,
                content: onePager.content,
                fullStory: onePager.fullStory,
                rating
            };
            const response = await axios[method](endpoint, payload);
            if (response.status === 200 || response.status === 201) {
                setOnePager(response.data.onePager);
                localStorage.setItem('currentOnePager', JSON.stringify(response.data.onePager));
                setShowNotification(true);
                setShowRatingModal(false);
                setRating(0);
                navigate('/saved-one-pagers');
            }
        } catch (error) {
            console.error('Error saving one-pager:', error);
            setError('Failed to save one-pager. Please try again.');
        } finally {
            setIsSaving(false);
        }
    };

    const regenerateTitle = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post('/api/one-pagers/regenerate-title', { 
                logline, 
                content: onePager?.content || '' 
            });
            console.log('Regenerate Title Response:', response.data);
            setTitle(response.data.title);
        } catch (error) {
            console.error('Error regenerating title:', error);
            setError('Failed to regenerate title. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const regenerateLogline = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post('/api/one-pagers/regenerate-logline', { 
                title, 
                content: onePager?.content || '' 
            });
            setLogline(response.data.logline);
        } catch (error) {
            console.error('Error regenerating logline:', error);
            setError('Failed to regenerate logline. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const toggleHelp = () => setIsHelpOpen(!isHelpOpen);

    const handleClear = () => {
        setOnePager(null);
        setTitle('');
        setLogline('');
        localStorage.removeItem('currentOnePager');
    };

    return (
        <div className="container mx-auto px-4 py-8 relative min-h-screen bg-white text-black">
            <NavigationMenu onHelpClick={toggleHelp} />
            <div className="w-full max-w-4xl mx-auto space-y-6 p-6 relative">
                <h1 className="text-3xl font-serif font-bold text-center text-black">One-Pager AI</h1>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <div className="space-y-4">
                    <textarea
                        ref={titleInputRef}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        style={{ fontSize: `${titleFontSize}px` }}
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 font-bold resize-none overflow-hidden"
                        placeholder="Movie Title"
                    />
                    <textarea
                        ref={loglineTextareaRef}
                        value={logline}
                        onChange={(e) => {
                            setLogline(e.target.value);
                            adjustTextareaHeight();
                        }}
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 resize-none overflow-hidden"
                        placeholder="Logline"
                        rows={2}
                    />
                    <div className="flex justify-center mt-4">
                        <button
                            onClick={() => generateOnePager()}
                            className="w-16 h-16 rounded-full bg-white text-black shadow-lg hover:bg-gray-100 transition-colors duration-200 relative border border-gray-300"
                            disabled={!title || !logline}
                        >
                            <div className="absolute inset-0 flex items-center justify-center">
                                {isLoading ? <FaSpinner className="animate-spin" size={24} /> : <FaMagic size={24} />}
                            </div>
                        </button>
                    </div>
                </div>
                {onePager && (
                    <div className="mt-8">
                        <h2 className="text-2xl font-bold mb-4">{onePager.title}</h2>
                        {/* Remove the following line to exclude the logline from the output */}
                        {/* <p className="mb-4 italic">{onePager.logline}</p> */}
                        <div className="whitespace-pre-wrap">{onePager.content}</div>
                        <div className="flex justify-between items-center mt-4">
                            <button
                                onClick={() => setFeedbackActionType('change') || setIsFeedbackDialogOpen(true)}
                                className="flex flex-col items-center text-black hover:text-gray-700"
                            >
                                <FaRedo size={24} />
                                <span className="text-xs mt-1">Change</span>
                            </button>
                            <button
                                onClick={() => setFeedbackActionType('refine') || setIsFeedbackDialogOpen(true)}
                                className="flex flex-col items-center text-black hover:text-gray-700"
                            >
                                <FaMagic size={24} />
                                <span className="text-xs mt-1">Refine</span>
                            </button>
                            <button
                                onClick={handleSave}
                                className="flex flex-col items-center text-black hover:text-gray-700"
                            >
                                <FaSave size={24} />
                                <span className="text-xs mt-1">Save</span>
                            </button>
                            <button
                                onClick={handleClear}
                                className="flex flex-col items-center text-black hover:text-gray-700"
                            >
                                <FaUndo size={24} />
                                <span className="text-xs mt-1">Clear</span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <AnimatePresence>
                {isLoading && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="absolute inset-0 bg-white bg-opacity-80 flex items-center justify-center z-50"
                    >
                        <FaSpinner className="text-black text-4xl animate-spin" />
                    </motion.div>
                )}
            </AnimatePresence>
            <Notification
                message="One-pager saved successfully!"
                isVisible={showNotification}
                onClose={() => setShowNotification(false)}
            />
            <HelpScreen isOpen={isHelpOpen} onClose={() => setIsHelpOpen(false)} />
            {showRatingModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg">
                        <h2 className="text-xl font-bold mb-4">Rate this one-pager</h2>
                        <div className="flex mb-4">
                            {[1, 2, 3, 4, 5].map((star) => (
                                <FaStar
                                    key={star}
                                    className={`cursor-pointer ${star <= rating ? 'text-yellow-400' : 'text-gray-300'}`}
                                    size={32}
                                    onClick={() => setRating(star)}
                                />
                            ))}
                        </div>
                        <div className="flex justify-end">
                            <button
                                onClick={() => setShowRatingModal(false)}
                                className="mr-2 px-4 py-2 bg-gray-200 rounded"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleRatingSubmit}
                                className="px-4 py-2 bg-black text-white rounded"
                                disabled={rating === 0}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isFullscreenContent && (
                <div className="fixed inset-0 bg-white z-50 p-4 overflow-auto">
                    <div className="max-w-4xl mx-auto">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-bold">{title}</h2>
                            <button onClick={() => setIsFullscreenContent(false)} className="p-2 bg-gray-200 rounded-full">
                                <FaExpand />
                            </button>
                        </div>
                        <textarea
                            value={onePager?.content}
                            onChange={(e) => setOnePager({ ...onePager!, content: e.target.value })}
                            className="w-full h-[calc(100vh-100px)] px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500"
                        />
                    </div>
                </div>
            )}
            <FeedbackDialog
                isOpen={isFeedbackDialogOpen}
                onClose={() => setIsFeedbackDialogOpen(false)}
                onSubmit={handleFeedbackSubmit}
                actionType={feedbackActionType}
            />
        </div>
    );
};

export default OnePagerGenerator;