import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface NotificationProps {
  message: string;
  isVisible: boolean;
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({ message, isVisible, onClose }) => {
  useEffect(() => {
    if (isVisible) {
      // Trigger haptic feedback if available
      if ('vibrate' in navigator) {
        navigator.vibrate(200);
      }

      // Automatically close the notification after 3 seconds
      const timer = setTimeout(() => {
        onClose();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          className="fixed inset-0 flex items-center justify-center z-50"
        >
          <div className="bg-white border-2 border-black text-black px-6 py-4 rounded shadow-lg font-serif max-w-xs w-full mx-4">
            <div className="text-center">{message}</div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Notification;